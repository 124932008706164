const CLASSES = {
  container: 'cmp-embed__pricespider',
  button: 'cmp-button',
};

const checkAndRebind = () => {
  if (window.PriceSpider?.WidgetBinder?.findUnboundWidgets().length > 0) {
    window.PriceSpider.rebind();
  }
};

const initPriceSpiderComponent = () => {
  const scriptUrl = document
    .querySelector(`.${CLASSES.container} script`)
    ?.getAttribute('data-src');

  if (scriptUrl) {
    const script = document.createElement('script');
    script.setAttribute('src', encodeURI(scriptUrl));
    document.head.appendChild(script);

    window.addEventListener('scroll', checkAndRebind);

    window.addEventListener('resize', checkAndRebind());
  }
};

if (document.readyState !== 'loading') {
  initPriceSpiderComponent();
} else {
  document.addEventListener('DOMContentLoaded', initPriceSpiderComponent);
}
