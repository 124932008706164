import { VisibilityObserver } from '../../utils/visibility';

const CLASSES = {
  spotifyPlaceholder: 'spotify-placeholder',
  spotifyComponent: 'cmp-embed__spotify',
};

const SPOTIFY_IFRAME_API_URL =
  'https://open.spotify.com/embed-podcast/iframe-api/v1';

const loadSpotify = () => {
  let initialised = false;
  let initQueue = [];
  let IFrameAPI;

  const initPlayer = (player) => {
    if (initialised) {
      loadPlayer(player);
    } else {
      initSpotifyApi();
      initQueue.push(player);
      tryRunLoadQueue();
    }
  };

  const setScriptLoaded = (script) => {
    window.Spotify.loaded = 'initialised';
    IFrameAPI = script;
    initialised = true;
    tryRunLoadQueue();
  };

  const initSpotifyApi = () => {
    if (window.Spotify.loaded !== 'not_started') {
      return;
    }

    window.Spotify.loaded = 'pending';

    const scriptElement = document.createElement('script');
    scriptElement.src = SPOTIFY_IFRAME_API_URL;

    const bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.appendChild(scriptElement);
  };

  const tryRunLoadQueue = () => {
    if (!initialised) return;

    initQueue.forEach((player, index) => {
      if (!player) return;

      loadPlayer(player);
      initQueue[index] = undefined;
    });
  };

  const getSpotifyTitle = async (spotifyUrl) => {
    const apiUrl = new URL('https://open.spotify.com/oembed');
    apiUrl.searchParams.set('url', encodeURI(spotifyUrl));

    try {
      const response = await fetch(apiUrl, { method: 'GET', mode: 'cors' });
      const data = await response.json();
      return data.title;
    } catch {
      return '';
    }
  };

  const emitSpotifyEvent = async (url) => {
    const event = {
      event: 'play_spotify',
      embed_title: await getSpotifyTitle(url),
      embed_url: url,
    };
    CepDataLayer.push(event);
  };

  const loadPlayer = (player) => {
    const { type, uri, src } = JSON.parse(player.dataset.iframe);
    let eventEmitted = false;

    const options = {
      height: type === 'track' ? 105 : 398,
      uri: uri,
    };

    const callback = (EmbedController) => {
      EmbedController.addListener('playback_update', (e) => {
        if (!e.data.isPaused && !eventEmitted) {
          emitSpotifyEvent(src);
          eventEmitted = true;
        } else if (e.data.isPaused) {
          eventEmitted = false;
        }
      });
    };

    IFrameAPI.createController(player, options, callback);
  };

  const initialize = () => {
    if (typeof window !== 'undefined') {
      window.onSpotifyIframeApiReady =
        window.onSpotifyIframeApiReady ||
        function (IFrameAPI) {
          console.log('Spotify IFrame API is ready', IFrameAPI);
        };

      window.Spotify = window.Spotify || { loaded: 'not_started' };
    }
    const spotifyEmbed = document.querySelector(`.${CLASSES.spotifyComponent}`);
    if (!Boolean(spotifyEmbed)) {
      return;
    }

    window.onSpotifyIframeApiReady = (IFrameAPI) => {
      console.log('callback');
      setScriptLoaded(IFrameAPI);
    };

    if (!window.Spotify) {
      window['Spotify'] = { loaded: 'not_started' };
    }

    const spotifyPlayers = document.querySelectorAll(
      `.${CLASSES.spotifyPlaceholder}`
    );

    spotifyPlayers.forEach((player) => {
      const observer = new VisibilityObserver(player, { threshold: 200 });
      observer.observe(() => {
        initPlayer(player);
      }, 'single');
    });
  };
  initialize();
};

if (document.readyState !== 'loading') {
  loadSpotify();
} else {
  document.addEventListener('DOMContentLoaded', loadSpotify);
}
