const loadHeader = () => {
  const header = document.querySelector('.header');
  const container = header.querySelector('.cmp-container');
  let mobileVisible = false;

  // Creates the hamburger menu
  const hamburgerButton = document.createElement('button');

  hamburgerButton.className = 'header__hamburger-button';
  hamburgerButton.innerHTML = `
      <div class="header__hamburger-bar"></div>
      <div class="header__hamburger-bar"></div>
      <div class="header__hamburger-bar"></div>
    `;

  header.querySelector('ul').id = 'hamburger-menu';

  container.insertBefore(hamburgerButton, container.firstChild);

  const toggleMenu = () => {
    const headerNavigation = document.querySelector('.header__navigation');
    if (mobileVisible) {
      hamburgerButton.setAttribute('aria-expanded', 'false');
      headerNavigation.classList.remove('header__navigation-visible');
    } else {
      hamburgerButton.setAttribute('aria-expanded', 'true');
      headerNavigation.classList.add('header__navigation-visible');
    }
    mobileVisible = !mobileVisible;
  };

  hamburgerButton.addEventListener('click', toggleMenu);

  const navigation = document.querySelector('.header__navigation');

  const liElementsWithUl = [...navigation.querySelectorAll('li > ul')].map(
    (e) => e.parentNode
  );

  const resetMenu = () => {
    const headerNavigation = document.querySelector('.header__navigation');

    headerNavigation.classList.remove('header__navigation-visible');

    if (window.innerWidth < 768) {
      hamburgerButton.setAttribute('aria-controls', `hamburger-menu`);
      hamburgerButton.setAttribute('aria-expanded', 'false');
    } else {
      hamburgerButton.removeAttribute('aria-expanded');
      hamburgerButton.removeAttribute('aria-controls');
    }
  };

  const toggle = (li) => {
    li.classList.toggle('opened');
    if (li.classList.contains('opened')) {
      li.setAttribute('aria-expanded', 'true');
      closeMenus(li);
    } else {
      li.setAttribute('aria-expanded', 'false');
    }
  };

  const closeMenus = (li = null) => {
    liElementsWithUl.forEach((element) => {
      if (element !== li) {
        element.classList.remove('opened');
        element.setAttribute('aria-expanded', 'false');
      }
    });
  };

  const handleResize = () => {
    if (mobileVisible) {
      toggleMenu();
      closeMenus();
    }
    resetMenu();
  };

  resetMenu();

  if (navigation) {
    liElementsWithUl.forEach((li, index) => {
      li.setAttribute('aria-expanded', 'false');
      li.setAttribute('tabindex', '0');
      li.querySelector('ul').id = `header-links-${index}`;
      li.setAttribute('aria-controls', `header-links-${index}`);

      li.addEventListener('mouseenter', (event) => {
        if (window.innerWidth > 768) {
          li.setAttribute('aria-expanded', 'true');
          closeMenus(li);
        }
      });

      li.addEventListener('mouseleave', (event) => {
        if (window.innerWidth > 768) {
          li.setAttribute('aria-expanded', 'false');
        }
      });

      li.addEventListener('click', (event) => {
        if (window.innerWidth <= 768) {
          toggle(li);
        }
      });

      li.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          toggle(li);
        }
      });

      li.addEventListener('blur', (event) => {
        if (window.innerWidth <= 768) {
          if (!liElement.contains(event.relatedTarget)) {
            liElement.classList.remove('opened');
            liElement.setAttribute('aria-expanded', 'false');
          }
        }
      });
    });

    // Adds 'has-ul' class to elements. Temp fix until 'has' selector is universally available in all browsers
    var liAndUlElements = document.querySelectorAll(
      '.header__navigation li, .header__navigation ul'
    );

    liAndUlElements.forEach((element) => {
      if (element.querySelector('ul')) {
        element.classList.add('has-ul');
      }
    });
  }

  window.addEventListener('resize', handleResize);
};

if (document.readyState !== 'loading') {
  loadHeader();
} else {
  document.addEventListener('DOMContentLoaded', loadHeader);
}
