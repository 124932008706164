
// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png"

// Component Javascript
import "./components/header/header.js";
import "./components/footer/footer.js";
import "./components/form-options/form_dropdown.js";
import "./components/progressbar/progressbar.js";
import "./components/tabs/tabs.js";
import "./components/embed/price-spider.js";
import "./components/embed/embed-third-party.js";
import "./components/embed/embed-spotify.js";

// favicons
import "./resources/favicons/apple-touch-icon.png"
import "./resources/favicons/favicon-32x32.png"
import "./resources/favicons/favicon-16x16.png"
import "./resources/favicons/safari-pinned-tab.svg"
