export const isVisibleVertical = (element, threshold = 0) => {
    const { top, bottom } = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const elementTopVisible = top < windowHeight + threshold && top > 0;
    const elementBottomVisible = bottom > 0 - threshold && bottom < windowHeight;

    return elementTopVisible || elementBottomVisible;
};

export const isVisible = (element) =>
    Boolean(element && (element.offsetWidth || element.offsetHeight || element.getClientRects().length));

class VisibilityObserver {
    constructor(element, options = { threshold: 0 }) {
        this.element = element;
        this.threshold = options.threshold;
        this.controller = new AbortController();
    }

    observe(callbackFn, occurrence = 'constant') {
        const tryInvokeCallback = () => {
            if (isVisibleVertical(this.element, this.threshold)) {
                callbackFn();
                if (occurrence === 'single') {
                    this.controller.abort();
                }
            }
        };

        tryInvokeCallback();
        window.addEventListener('scroll', tryInvokeCallback, { signal: this.controller.signal });
        window.addEventListener('resize', tryInvokeCallback, { signal: this.controller.signal });
    }

    stopObserve() {
        this.controller.abort();
    }
}

export { VisibilityObserver };
