const openAccordion = (btn, tabList) => {
  btn.setAttribute('aria-expanded', true);
  btn.classList.add('expanded');
  tabList.classList.add('expanded');
}

const closeAccordion = (btn, tabList) => {
  btn.setAttribute('aria-expanded', false);
  btn.classList.remove('expanded');  
  tabList.classList.remove('expanded');
}

const setUpFAQTabStyle = (component, index) => {
  const tabContainer = component.querySelector('.cmp-tabs');
  const tabList = tabContainer?.querySelector('.cmp-tabs__tablist');
  const tabListId = `cmp-tab-list-${index}`;
  tabList.setAttribute('id', tabListId);

  // State variables
  let isOpen = false;

  // Render dropdown
  const accordionBtn = document.createElement('button');
  accordionBtn.classList.add('cmp-tabs__dropdown-selector');
  accordionBtn.setAttribute('aria-expanded', isOpen);
  accordionBtn.setAttribute('aria-controls', tabListId);
  accordionBtn.textContent = 'Sort By'
  tabContainer.prepend(accordionBtn);

  // Open/Close accordion on accordionBtn click
  accordionBtn.addEventListener('click', () =>{ 
    isOpen = !isOpen;
    
    if(isOpen) {
      openAccordion(accordionBtn, tabList);
    } else {
      closeAccordion(accordionBtn, tabList);
    }
  });
}

const loadTabStyles = () =>{ 
  const tabComponent = document.querySelectorAll('.tabs.faq');

  tabComponent?.forEach((component, i) => {
    if(component.classList.contains('faq')) {
      setUpFAQTabStyle(component, i);
    }
  })
}

if (document.readyState !== 'loading') {
  loadTabStyles();
} else {
  document.addEventListener('DOMContentLoaded', loadTabStyles);
}