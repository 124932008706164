const loadFooter = () => {
  // This handles functionality related to tabbing for accessibility

  const footerLinks = document.querySelector('.footer .footer__links');
  const firstCmpText = footerLinks.querySelector('.cmp-text ul');

  findSections();

  window.onresize = () => {
    resetTabs(firstCmpText);
    closeMenus();
  };

  resetTabs(firstCmpText);

  // Add tabbing to mobile and implements mobile clicking on sections
  liItems().forEach((li) => {
    if (li.parentNode === firstCmpText && li.querySelector('ul')) {
      li.addEventListener('click', () => {
        if (window.innerWidth <= 768) {
          toggle(li);
          closeMenus(li);
        }

        liItems().forEach((element) => {
          if (element !== li && element.classList.contains('opened')) {
            element.setAttribute('aria-expanded', 'false');
            element.classList.remove('opened');
          }
        });
      });

      li.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          toggle(li);
          closeMenus(li);
        }
      });
    }
  });
};

const liItems = () => {
  return document
    .querySelector('.footer .footer__links')
    .querySelector('.cmp-text ul')
    .querySelectorAll('li');
}

const closeMenus = (li = null) => {
  liItems().forEach((element) => {
    if ((!li || element !== li) && element.querySelector('ul')) {
      element.classList.remove('opened');
      element.setAttribute('aria-expanded', 'false');
    }
  });
};

// Resets if the screen size changes
const resetTabs = (firstCmpText) => {
  liItems().forEach((li, index) => {
    if (li.parentNode === firstCmpText && li.querySelector('ul')) {
      li.querySelector('ul').id = `footer-links-${index}`;

      if (window.innerWidth <= 768) {
        li.setAttribute('tabindex', '0');
        li.setAttribute('aria-expanded', 'false');
        li.setAttribute('aria-controls', `footer-links-${index}`);
      } else {
        li.setAttribute('tabindex', '-1');
        li.removeAttribute('aria-expanded');
        li.removeAttribute('aria-controls');
      }
    }
  });
};

const toggle = (li) => {
  li.classList.toggle('opened');
  if (li.classList.contains('opened')) {
    li.setAttribute('aria-expanded', 'true');
    closeMenus(li);
  } else {
    li.setAttribute('aria-expanded', 'false');
  }
};

const findSections = () => {
  const targetElements = document.querySelectorAll(
    '.footer__links > .cmp-text > ul > li'
  );
  const elementsWithLiChild = Array.from(targetElements).filter((element) =>
    element.querySelector('li')
  );
  elementsWithLiChild.forEach((element) => {
    element.classList.add('has-li');
  });
};

if (document.readyState !== 'loading') {
  loadFooter();
} else {
  document.addEventListener('DOMContentLoaded', loadFooter);
}
